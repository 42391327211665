import React, { Component } from 'react';
import firebase from 'firebase';
import CurrentUser from '../core/PublicUserSessionContext';
import { Redirect } from 'react-router-dom';
import { countries } from '../../../resources/js/data';
import Form from 'react-bootstrap/Form'
import LoginComponent from '../../commons/LoginComponent';
import FormPhoneInput from '../../commons/FormPhoneInput';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

class ProfileUserFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { user: {}, redirect: false, errors: {}, universities: [{}], isSessionRenewed: false };
    }

    componentDidMount() {
        firebase.firestore().collection("organizations").get().then(organizations => {
            let universities = []
            organizations.docs.forEach(organization => {
                if (!organization.data().private)
                    universities.push({ id: organization.id, title: organization.data().title })
            });
            this.setState({ universities })
        }).catch(error => {
            console.log(error)
        })
    }

    sessionRenewed = (userId) => {
        if (userId) {
            firebase.firestore().collection("users").doc(userId).get()
                .then((doc) => {
                    if (doc.exists) {
                        this.setState({ user: { id: doc.id, name: doc.data().name, ...doc.data() }, oldEmail: doc.data().email, isSessionRenewed: true });
                    } else {
                        this.setState({ error: "Usuario no encontrado" });
                    }
                }).catch(error => {
                    console.log("Error al recuerar usuario: ", error);
                })
        } else {
        }
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        if (this.validate()) {
            this.saveUser();
        }
    }

    validate() {
        const values = this.state.user;
        const errors = {};
        const requiredMessage = "Debes llenar este campo";

        if (!values.name) { errors.name = requiredMessage }

        if (!values.country) { errors.country = requiredMessage }

        if (!values.city) { errors.city = requiredMessage }

        if (!values.email) {
            errors.email = requiredMessage;
        } else {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(values.email).toLowerCase())) {
                errors.email = "Introduce un correo válido.";
            }
        }

        if(!values.phone_number){
            errors.phone_number = requiredMessage;
        }else{
            const phone_number = values?.phone_number ?? "";

            if(isPossiblePhoneNumber(phone_number) === false){
                errors.phone_number = "Introduce un número de teléfono válido.";
            }
        }

        if (values.password) {
            if (values.password.length < 6) {
                errors.password = "La contraseña debe tener al menos 6 caracteres.";
            }
        }

        this.setState({ errors });
        if (!Object.keys(errors).length) {
            return true;
        }

    }

    saveUser = () => {
        const user = this.state.user;
        const oldEmail = this.state.oldEmail;
        if (user.email != oldEmail) {
            this.validateEmail();
        } else if (user.password) {
            this.changePassword();
        } else {
            this.saveOnDb();
        }
    }
    validateEmail = () => {
        firebase.firestore().collection('users').where(`email`, '==', this.state.user.email).get().then((snapshot) => {
            if (snapshot.empty) {
                this.changeMail();
            } else {
                this.setState({ errors: { email: "Este correo ya esta registrado, elige otro por favor." } });
            }
        }).catch(err => {
            this.context.mostrarError("Ocurrió un error, por favor recarga la página", "ERROR SIGNUP");
        });
    }

    changeMail = () => {
        const newUser = this.state.user;
        const oldEmail = this.state.oldEmail;
        const userAuth = firebase.auth().currentUser;
        userAuth.updateEmail(newUser.email)
            .then(() => {
                if (newUser.password) {
                    this.changePassword();
                } else {
                    this.saveOnDb();
                }
            }).catch((error) => {
                console.log("error correo", error);
                newUser.email = oldEmail;
                this.setState({ user: newUser });
            });
    }

    changePassword = () => {
        const userAuth = firebase.auth().currentUser;
        userAuth.updatePassword(this.state.user.password)
            .then(() => {
                this.saveOnDb();
            }).catch((error) => {
                console.log("error contraseña", error);
            });
    }

    saveOnDb = () => {
        const user = this.state.user;
        const db = firebase.firestore();
        db.collection("users").doc(user.id).set({
            name: user.name,
            email: user.email,
            country: user.country,
            city: user.city,
            promosCxVet: user.promosCxVet,
            newsCxVet: user.newsCxVet,
            type: user.type,
            paymentDay: user.paymentDay,
            subscriptionId: user.subscriptionId,
            terminationDate: user.terminationDate,
            statusPago: user.statusPago,
            date: user.date,
            university: user.university || "",
            userNumber: user.userNumber,
            phone_number: user.phone_number || "",
        })
            .then((docRef) => {
                this.props.history.push("/perfil")
            })
            .catch(function (error) {
                console.error("Error : ", error);
            })
    }

    handleInputChange = (event) => {
        let value = event.target.value;
        const element = event.target.name;
        const user = this.state.user;
        const type = event.target.type;
        if (type == 'checkbox') {
            value = event.target.checked;
        }
        user[element] = value;
        this.setState({ user });
    }

    getLoginComponent = () => {
        return (
            <LoginComponent message='Para modificar tu información debes volver a iniciar a sesión.' sessionRestart={true} sessionRenewed={this.sessionRenewed} />
        )
    }

    render() {
        if (!this.state.isSessionRenewed) { return this.getLoginComponent() }
        const user = this.state.user;
        const errors = this.state.errors;
        return (
            <section className="register-page">
                {this.state.redirect && <Redirect to="/perfil" />}
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <h1 className="">
                                Mi cuenta
                            </h1>
                            <h2 className="subtitle mb-5">
                                Administrar información personal
                            </h2>
                            <div className="row justify-content-center ">
                                <div className="col-md-6">
                                    <Form onSubmit={this.handleFormSubmit}>
                                        <div className="form-register">
                                            <h4>Información básica</h4>
                                            <div className="form-group">
                                                <label htmlFor="newName" >Nombre</label>
                                                <Form.Control isInvalid={errors.name} onChange={this.handleInputChange} type="text" id="name" name="name" value={user.name} className="form-control" />
                                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact_email">Correo Electrónico</label>
                                                <Form.Control isInvalid={errors.email} onChange={this.handleInputChange} type="email" id="email" name="email" value={user.email} className="form-control" />
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber">Número de teléfono</label>
                                                <FormPhoneInput id="contact_phone_number" name="phone_number" value={user.phone_number} onChange={this.handleInputChange} error={errors.phone_number} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Contraseña</label>
                                                <Form.Control isInvalid={errors.password} onChange={this.handleInputChange} type="password" id="password" name="password" value={user.password} className="form-control" />
                                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                            </div>
                                            <h4>Universidad </h4>
                                            <div className="form-group">
                                                <label from="country">Opcional, si perteneces a una universidad selecciona aquí</label>
                                                <Form.Control as="select" onChange={this.handleInputChange} id="university" name="university" value={user.university} className="form-control"  >
                                                    <option value="">Seleccionar uno</option>
                                                    {Array.isArray(this.state.universities) && this.state.universities.map(university => {
                                                        return (<option value={university.id}> {university.title} </option>);
                                                    })}
                                                </Form.Control>
                                            </div>
                                            <h4>Ubicación</h4>
                                            <div className="form-group">
                                                <label from="country">País</label>
                                                <Form.Control as="select" isInvalid={errors.country} onChange={this.handleInputChange} id="country" name="country" value={user.country} className="form-control"  >
                                                    <option value="">Seleccionar uno</option>
                                                    {Array.isArray(countries) && countries.map(country => {
                                                        return (<option value={country}> {country} </option>);
                                                    })}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                                            </div>
                                            <div className="form-group">
                                                <label from="city">Ciudad</label>
                                                <Form.Control isInvalid={errors.city} onChange={this.handleInputChange} type="text" id="city" name="city" value={user.city} className="form-control" />
                                                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                                            </div>
                                            <h4>Preferencias de correo</h4>
                                            <div className="mb-3">
                                                <div className="form-check">
                                                    <input onChange={this.handleInputChange} id="promosCxVet" name="promosCxVet" checked={user.promosCxVet} type="checkbox" className="form-check-input" />
                                                    <label htmlFor="promosCxVet" className="form-check-label" >Quiero recibir correos promocionales de CxVet</label>
                                                </div>
                                                <div className="form-check">
                                                    <input onChange={this.handleInputChange} id="newsCxVet" name="newsCxVet" checked={user.newsCxVet} type="checkbox" className="form-check-input" />
                                                    <label className="form-check-label" >Quiero recibir correos con novedades de videos</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group btn-row">
                                            <a href="/perfil" className="btn btn-secondary ">
                                                CANCELAR
                                            </a>
                                            <button type="submit" className="btn btn-secondary">
                                                GUARDAR
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
ProfileUserFormComponent.contextType = CurrentUser;
export default ProfileUserFormComponent;


