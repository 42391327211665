import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';

/**
 * @typedef {{
 *  target: {
 *      name: string,
 *      value: string
 *  },
 *  value: string
 * }} OnChangeEvent
 * 
 * @param {{
 *  name?: string,   
 *  value: string|null,
 *  error?: string|null,
 *  className?: string,
 *  onChange: (event: OnChangeEvent) => void
 * }} param0 
 * @returns 
 */
export default function FormPhoneInput({ name, value, onChange, error, className, ...props }) {
    /**
     * @param {string} phone_number 
     */
    function handleInputChange(phone_number) {
        onChange(makeOnChangeEvent(name, phone_number));
    }

    /**
     * @param {string} name 
     * @param {string} value 
     */
    function makeOnChangeEvent(name, value) {
        return {
            target: {
                name: name,
                value,
            },
            value,
        }
    }

    return (
        <>
            <PhoneInput
                className={`${error ? 'is-invalid' : ''} ${className ? className : ''}`}
                international
                defaultCountry="MX"
                value={value}
                onChange={handleInputChange}
                {...props}
            />
            {error && <div className="input-phone-error">{error}</div>}
        </>
    );
}