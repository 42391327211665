import React, { Component } from 'react';
import firebase from 'firebase';
import TagItemComponent from './TagItemComponent';
import TypeAlertComponent from './TypeAlertComponent';
import CurrentUser from '../core/PublicUserSessionContext';
import {Redirect} from 'react-router-dom';
import { registrar } from "../../../resources/js/eventos";
import ImagenPdf from '../../../resources/images/pdf-image.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

import InvalidTokenComponent from './InvalidTokenComponent'
import { userCanAccessAula } from '../../../utils/aula.utils';

class ProcedureComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { procedure: {}, isFilled: false, typeAlert: false, isDemo: true, redirectToSingUp: false };
        this.video = React.createRef();
    }

    componentWillMount = () => {
        const { match: { params } } = this.props;
        let id = params.id
        const currentUser = this.context.currentUser;

        this.setToken();
        this.getTags();

        this.getProcedure(id);

    }

    getProcedure = (id, aulaAccess = false) => {
        const currentUser = this.context.currentUser;
        firebase.firestore().collection('procedures').doc(id).get().then((doc) => {
            if (doc.exists) {
                const procedure = { id: doc.id, ...doc.data() };
                const isDemo = (currentUser.type === "Gest" || (currentUser.type === "Gratis" && procedure.type === "Premium" && !aulaAccess))

                if (isDemo) {
                    procedure.video = procedure.demo;
                    procedure.fascicle = "";
                }
                this.setState({ procedure, id: procedure.id, isDemo, isFilled: true });
                registrar({ categoria: "VIDEOS", etiqueta: doc.id, valor: 0, accion: this.context.currentUser.type });
            } else {
                console.log("No such document!");
            }
        }).catch(err => this.context.mostrarError("No se pudo cargar la lista de cirugías, intente mas tarde", "ERROR PROCEDIMIENTOS"));
    }

    getTags = () => {
        firebase.firestore().collection('tags').get().then((snapshot) => {
            const tags = [];
            snapshot.docs.map((doc) => tags.push({ id: doc.id, ...doc.data() }));
            this.setState({ tags });
        }).catch(error => {
            console.log(error);
            this.context.mostrarError("No se pudo cargar la lista de etiquetas", "ERROR ETIQUETAS")
        });
    }

    setNewToken = () => {
        this.setToken();
        this.getProcedure(this.state.id);
    }

    setToken = () => {
        const currentUser = this.context.currentUser;
        if (currentUser.id) {
            const userToken = Math.random();
            const userTokenRef = firebase.firestore().collection('userToken').doc(currentUser.id);
            userTokenRef.set({ token: userToken })
                .then((snapshot, algomas) => {
                    this.setState({ userToken, isInvalidToken: false });
                }).catch(err => console.log(err)).finally(st => {
                    console.log("finally:", st);
                    this.tokenListener();
                });
        }
    }

    tokenListener = () => {
        const currentUser = this.context.currentUser;
        const tokenListener = firebase.firestore().collection('userToken').doc(currentUser.id).onSnapshot(observer => {
            if (this.state.userToken !== observer.data().token) {
                this.setState({ procedure: {}, isFilled: false, isInvalidToken: true });
            }
        });
    }

    currentTime = (time) => {
        const vid = document.getElementById("video");
        const timeParts = time.split(":");
        let seconds = (timeParts[0] * 3600) + (timeParts[1] * 60) + (parseInt(timeParts[2]));
        vid.currentTime = seconds;
    }

    redirectToSingUp = () => {
        let redirect = "";
        if (this.context.currentUser.type === "Gest") {
            //redirect  = <Redirect to='/registro' />;
            this.props.history.push("/registro")
        } else {
            //redirect =  <Redirect to='/perfil/pago' />;
            this.props.history.push("/perfil");
        }
        //this.setState({redirectToSingUp: redirect});
    }

    descargaFasciculo = (tipo, membresia) => {
        registrar({ categoria: "FASCICULOS", etiqueta: this.state.procedure.id, valor: 0, accion: this.context.currentUser.type });
        if (tipo === "gratis") {
            if (membresia === "Gest") {
                this.setState({ typeAlert: true });
            }
            else {

            }
        }
        else {
            if (membresia === "otro") {
                this.setState({ typeAlert: true });
                //this.setState({ typeAlert: true });
            }
        }
    }

    checkForAulaAccess = () => {
        let currentUser = this.context.currentUser;
        let id = this.props.match.params.id
        let aulaAccess = false

        currentUser.aulaData.forEach(aula => {
            if (aula.videos.includes(id) && userCanAccessAula(currentUser.id, aula)) {
                aulaAccess = true
                if (this.state.isDemo)
                    this.getProcedure(id, true)
            }
        })

        return aulaAccess
    }

    render() {

        if (this.state.isInvalidToken) return <InvalidTokenComponent show={true} setNewToken={this.setNewToken} />;
        if (!this.state.isFilled) return null;
        const isDemo = this.state.isDemo;
        const procedure = this.state.procedure;
        console.log("render >>>>>>>>>>>>>>>>>>>> procedure", procedure);
        let currentUser = this.context.currentUser;
        let typeAlertClose = () => this.setState({ typeAlert: false });
        const urlKynd = `/procedimientos/kynd/${procedure.kynd}`;

        let aulaAccess = currentUser.type === "Gest" ? false : this.checkForAulaAccess()

        return (
            <React.Fragment >
                {this.state.redirectToSingUp && <Redirect to='/registro' />}

                <section className="video-page">
                    <div className="bg-light ">
                        <div name="video ">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-title my-3">
                                            <h1 className="video-title mb-0">
                                                {procedure.name}
                                            </h1>
                                            <div className="video-tags">
                                                <li className=""><a href={urlKynd}>{procedure.kynd}</a></li>
                                                {Array.isArray(this.state.tags) && this.state.tags.map(tag => {
                                                    if (procedure.tags.hasOwnProperty(tag.id)) {
                                                        return (
                                                            <TagItemComponent key={tag.id} tag={tag} />
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        {(isDemo && !aulaAccess) &&
                                            <div className="alert-register alert alert-info justify-content-between d-md-flex align-items-center text-md-left text-center mb-4" role="alert">

                                                {procedure.type === "Gratis" ?
                                                    <React.Fragment >

                                                        <span>
                                                            <h4 className="alert-heading">Éste es un contenido gratuito</h4>
                                                            <p className="mb-0">Este es un <span className="font-weight-bold">demo</span> de la cirugía que podrás consultar registrándote a la membresía Básica sin costo de CxVet</p>
                                                        </span>
                                                        <a href="/registro" className=" mx-sm-4 my-2 btn btn-secondary text-nowrap">UNIRTE A CXVET</a>
                                                    </React.Fragment >
                                                    :
                                                    <React.Fragment >
                                                        <span>
                                                            <h4 className="alert-heading">Éste es un contenido premium</h4>
                                                            <p className="mb-0">Este es un  <span className="font-weight-bold">demo</span> de la cirugía que podrás consultar con tu membresía Premium. Regístrate a la membresía Premium para tener acceso a todo el catálogo de cirugías en video y guías PDF.</p>
                                                        </span>
                                                        <a href="/perfil" className=" mx-sm-4 my-2 btn btn-secondary text-nowrap">UNIRTE A CXVET PREMIUM</a>
                                                    </React.Fragment >
                                                }


                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="videoplayer-container h-100">
                                            <video onContextMenu={e => e.preventDefault()} id="video" className="w-100 h-100" controls src={procedure.video}
                                                controls controlsList="nodownload" ></video>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3 " name="marcador">

                                        <p className="h4" >Secciones navegables</p>
                                        <ul className="list-unstyled pl-3 mt-3">
                                            {Array.isArray(procedure.times) && procedure.times.map((time, index) => {
                                                return (
                                                    <li key={index}>
                                                        <p onClick={isDemo ? "" : (e) => this.currentTime(time.time)}
                                                            className={isDemo ? "" : "link-no-btn"} >
                                                            <span className="mr-2"><FontAwesomeIcon icon={faPlayCircle} /></span>
                                                            {time.name}
                                                        </p>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>






                                <div className="row mt-4">


                                    <div className="col-md-8">
                                        <div className="">
                                            <p className="h4">Descripción de la cirugía</p>
                                            {procedure.detail.split("\n").map(parrafo => <p>{parrafo}</p>)}
                                        </div>
                                    </div>
                                    {procedure.fascicle &&
                                        <div className="col-md-4">
                                            <div name="guia ">
                                                <div className="text-center tarjeta-bold p-4">
                                                    <p className="d-none h5 mb-3">Descarga la guía en PDF</p>
                                                    <img className="mb-3 d-none" src={ImagenPdf} />
                                                    {procedure.type === "Gratis" ?
                                                        (currentUser.type === "Gest" || (currentUser.type === "Gratis" && !aulaAccess)) ?
                                                            <button type="button" className="  btn btn-secondary btn-lg btn-pdf" onClick={() => this.descargaFasciculo("gratis", "Gest")}  >
                                                                DESCARGA LA GUÍA CON TU MEMBRESÍA PREMIUM
                                                            </button>
                                                            : <a className="  btn btn-secondary btn-lg btn-pdf" target="_blank" href={procedure.fascicle} onClick={() => { this.descargaFasciculo("gratis", "otra") }}>
                                                                DESCARGA LA GUÍA
                                                            </a>
                                                        :
                                                        (currentUser.type === "Gest" || (currentUser.type === "Gratis" && !aulaAccess)) ?
                                                            <button type="button" className="  btn btn-secondary btn-lg btn-pdf"
                                                                onClick={() => { this.descargaFasciculo("premium", "otro") }}>
                                                                DESCARGA LA GUÍA CON TU MEMBRESÍA PREMIUM
                                                            </button>
                                                            :
                                                            <a className="  btn btn-secondary btn-lg btn-pdf" target="_blank" href={procedure.fascicle}
                                                                onClick={() => { this.descargaFasciculo("premium", "premium") }}>
                                                                DESCARGA LA GUÍA
                                                            </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>



                            </div>
                        </div>
                    </div>
                </section>

                <TypeAlertComponent show={this.state.typeAlert} onHide={typeAlertClose} redirectFunction={this.redirectToSingUp} />
            </React.Fragment>
        )
    }
}

ProcedureComponent.contextType = CurrentUser;

export default ProcedureComponent;