import React, { Component } from 'react';
import firebase from 'firebase';
import CurrentUser from '../core/PublicUserSessionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEnvelope, faUser, faCheckCircle, faGlobe, faMapMarkerAlt, faCertificate, faUniversity, faPhone } from '@fortawesome/free-solid-svg-icons';
import Alert from "react-bootstrap/Alert";
import ProfilePaymentFormComponent from './ProfilePaymentFormComponent';
import { jsPDF } from "jspdf";
class UserProfileComponent extends Component {
    constructor() {
        super();
        this.state = {
            aulas: [],
            type: ""
        }
    }

    componentWillMount = () => {
        let currentUser = this.context.currentUser;
        firebase.firestore().collection("users").doc(currentUser.id).get().then(doc => {
            if (doc.exists) {
                this.setState({ currentUser: { id: doc.id, ...doc.data() }, type: currentUser.type });
                this.setUniversityTitle(doc.data().university, currentUser.universityTitle)
            } else {
                this.setState({ currentUser });
            }

            // if (doc.data().type === "Admin")
            //     this.props.history.push("/");
        }).catch(error => {
            this.setState({ currentUser });
        });
    }

    setUniversityTitle = (university, contextTitle) => {
        firebase.firestore().collection("organizations").doc(university).get().then(doc => {
            this.setState({ currentUser: { ...this.state.currentUser, universityTitle: doc.data().title } });
        }).catch(error => {
            this.setState({ currentUser: { ...this.state.currentUser, universityTitle: contextTitle } });
            console.log(JSON.stringify(error))
        });
    }

    downloadCertificate = async (diplomado) => {
        let image = new Image;
        image.crossOrigin = "anonymous";
        image.src = diplomado.certificateURL;

        const doc = new jsPDF({
            orientation: "landscape",
            unit: "in",
            format: [16, 12]
        });
        doc.addImage(image, "JPEG", .5, .5, 15, 11);

        doc.setFontSize(28)
        doc.text(this.state.currentUser.name, 8.8, 6.4, 'center');
        doc.text(`Folio: ${diplomado.folio}`, 14.5, 9.64, 'center');

        doc.save(`certificado-${diplomado.name}.pdf`);
        //this.props.onHide();
    }

    render() {
        let currentUser = this.state.currentUser;
        if (!currentUser) return null;
        if (currentUser.type === "Premium") {
            let date = new Date(currentUser.paymentDay)
            date.setMonth(date.getMonth() + 1);
            currentUser.paymentDay = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        }
        return (
            <section className="register-page">
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <h1 className="mb-5">Mi CxVet</h1>
                            <div className="row justify-content-center ">
                                <div className="col-lg-12 perfil-title">
                                    <h4 className="mb-0">{currentUser.name} - {currentUser.userNumber}</h4>
                                    <p>{this.state.type === "Gratis" ?
                                        "Membresía Básica"
                                        :
                                        (currentUser.statusPago === "POR_TERMINAR" || currentUser.statusPago === "CANCELLED") ?
                                            "Membresía Básica"
                                            :
                                            "Membresía Premium"}
                                    </p>
                                </div>
                                <div className="col-md-5 col-lg-4 mb-4">
                                    <div className="tarjeta-bold p-3">
                                        <p className="font-weight-bold mb-1">Información personal</p>
                                        <div className="fa-label">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            {currentUser.email}
                                        </div>
                                        <div className="fa-label">
                                            <FontAwesomeIcon icon={faPhone} />
                                            {currentUser.phone_number || "N/A"}
                                        </div>
                                        <div className="fa-label">
                                            <FontAwesomeIcon icon={faGlobe} />
                                            {currentUser.country}
                                        </div>
                                        <div className="fa-label">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                            {currentUser.city}
                                        </div>
                                        {currentUser.university && <div className="fa-label">
                                            <FontAwesomeIcon icon={faUniversity} />
                                            {currentUser.universityTitle}
                                        </div>}
                                        <div>

                                            <p className="font-weight-bold mb-1 mt-3">Preferencias de correo</p>
                                            <div className="mb-2">
                                                {currentUser.promosCxVet || currentUser.newsCxVet ?
                                                    <p>
                                                        {currentUser.promosCxVet && <span className="fa-label"><FontAwesomeIcon icon={faCheckCircle} />Estas recibiendo correos promocionales de CxVet </span>}
                                                        {currentUser.newsCxVet && <span className="fa-label"><FontAwesomeIcon icon={faCheckCircle} />Estas recibiendo correos con novedades de CxVet </span>}
                                                    </p>
                                                    :
                                                    <span className="fa-label"><FontAwesomeIcon icon={faBan} />Tienes deshabilitada la recepción de correos de CxVet</span>
                                                }
                                            </div>
                                        </div>
                                        <a href="/perfil/usuario" className="btn btn-secondary btn-sm ">
                                            Editar perfil
                                        </a>
                                    </div>
                                </div>

                                <div className="col-md-7 col-lg-6">
                                    <ProfilePaymentFormComponent updateType={(type) => this.setState({ currentUser: { ...this.state.currentUser }, type: type })} />

                                    <Alert show={currentUser.statusPago == "FALLIDO"} variant="warning">
                                        No se pudo realizar el pago de tu suscripción , tu membresía ha sido cambiada a básica.
                                    </Alert>
                                </div>

                                {this.state.currentUser?.completedDiplomados && currentUser.type === "Premium" &&
                                    <>
                                        <div className="col-md-5 col-lg-4 mb-4">
                                            <div className="tarjeta-bold p-3">
                                                <p className="font-weight-bold mb-1">Diplomadoy y certificados</p>

                                                {this.state.currentUser?.completedDiplomados?.map(diplomado => {
                                                    return (
                                                        <div style={{ display: "flex", flexDirection: "column", marginTop: "12px" }}>
                                                            <div className="fa-label">
                                                                <FontAwesomeIcon icon={faCertificate} />
                                                                {diplomado.name}
                                                            </div>
                                                            <a onClick={() => this.downloadCertificate(diplomado)} className="btn btn-secondary btn-sm " style={{ width: "75%", color: "white" }}>
                                                                Descargar certificado
                                                            </a>
                                                        </div>)
                                                })}

                                            </div>
                                        </div>

                                        <div className="col-md-7 col-lg-6" style={{ opacity: 0, pointerEvents: "none" }}>
                                            <ProfilePaymentFormComponent updateType={() => { }} />
                                        </div>
                                    </>}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
UserProfileComponent.contextType = CurrentUser;
export default UserProfileComponent;