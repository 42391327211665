import { ACCESO_LIBRE } from "../service/constants";

/**
 * @param {string} userId 
 * @param {{
 *  users?: string[],
 *  accessType?: string,
 * }} aula 
 */
export function userCanAccessAula(userId, aula) {
    if (!aula) return false;

    if (aula.accessType === ACCESO_LIBRE) return true;

    if (!userId) return false;
    if (! Array.isArray(aula.users)) return false;

    return aula.users.includes(userId);
}