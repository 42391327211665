import React, { Component } from 'react';
import firebase from 'firebase';
import DeleteAlertCompoment from '../core/DeleteAlertComponent';
import { countries } from '../../../resources/js/data';
import CurrentUser from "../../public/core/PublicUserSessionContext";
import { getUniversities } from "../../../service/organizations"
import { getUser } from '../../../service/users';
import { GRATIS } from '../../../service/constants';
import FormPhoneInput from '../../commons/FormPhoneInput';
class UserFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userID: this.props.match.params.id,
            editing: this.props.match.params.id ? true : false,
            user: {
                type: GRATIS,
                date: "03/06/2019",
                paymentDay: "Sin cobro",
                terminationDate: "",
                statusPago: "BIEN",
                subscriptionId: "",
            },
            universities: [],
            modalShow: false
        };
        this.db = firebase.firestore();
    }

    componentDidMount = () => {
        this.getInitialData()
    }
    getInitialData = async () => {
        let { userID, editing, user } = this.state
        let universities = await getUniversities()

        if (editing)
            user = await getUser(userID)

        this.setState({ user, email: user.email, universities });
    }

    handleInputChange = (user) => {
        const value = user.target.value;
        const name = user.target.name;
        const element = this.state.user;
        const errors = Object.assign({}, this.state.errors);
        delete errors[name];
        element[name] = value;
        this.setState({ element, errors });
    }

    saveUser = () => {
        if (this.state.editing) {
            this.editUser();
        } else {
            this.createUser();
        }
    }

    createUser = () => {
        const user = this.state.user;
        const firebaseUser = { email: user.email, password: user.password };
        const createUserFunction = firebase.functions().httpsCallable('createUser');
        createUserFunction({ email: user.email, password: user.password }).then((result) => {
            console.log(result);
            const user = this.state.user;
            user.id = result.data.uid;
            this.setState({ user });
            console.log(user);
            this.saveOnDb();
        });
    }


    editUser = () => {
        const user = this.state.user;
        if (user.email != this.state.email || user.password) {
            this.updateUserAuth();
        } else {
            this.saveOnDb();
        }
    }


    updateUserAuth = () => {
        const user = this.state.user;
        const firebaseUser = { uid: user.id, email: user.email, password: user.password };
        const updateUserFunction = firebase.functions().httpsCallable('updateUser');
        updateUserFunction({ uid: user.id, email: user.email, password: user.password }).then((result) => {
            console.log(result);
            this.saveOnDb();
        });
    }

    deleteUser = () => {
        this.setState({ modalShow: false })
        let that = this;
        const user = this.state.user;
        const deleteUserFunction = firebase.functions().httpsCallable('deleteUser');
        this.context.iniciaCarga();
        deleteUserFunction({ uid: user.id }).then((result) => {
            console.log(result);
            this.db.collection('users').doc(this.state.user.id).delete().then(() => {
                console.log("Eliminado");
                this.props.history.goBack()
            })
        }).catch(function (error) {
            console.log("ERROR ELIMINAR USUARIO");
            that.context.mostrarError("No se pudo eliminar usuario", "ERROR ADMIN USUARIO")
            //console.error("Error: ", error);
        }).finally(r => {
            this.context.terminaCarga();
        });

    }


    saveOnDb = () => {
        const user = this.state.user;
        console.log(user);
        const db = firebase.firestore();
        if (this.state.edit) {
            let data = {
                name: this.state.user.name,
                email: this.state.user.email,
                type: this.state.user.type,
                date: this.state.user.date,
                newsCxVet: this.state.user.newsCxVet,
                paymentDay: this.state.user.paymentDay,
                promosCxVet: this.state.user.promosCxVet,
                type: this.state.user.type,
                country: this.state.user.country,
                city: this.state.user.city,
                subscriptionId: this.state.user.subscriptionId,
                statusPago: user.statusPago,
                terminationDate: user.terminationDate,
                university: user.university,
                userNumber: user.userNumber,
                phone_number: user.phone_number || ""
            }

            console.log(data);
            db.collection("users").doc(user.id).set(data)
                .then((docRef) => {
                    this.props.history.goBack()
                })
                .catch(function (error) {
                    console.error("Error : ", error);
                });

        } else {
            db.collection("users").doc(user.id).set({
                name: this.state.user.name,
                email: this.state.user.email,
                type: this.state.user.type,
                phone_number: this.state.user.phone_number || "",
                date: Date.now(),
                newsCxVet: false,
                paymentDay: "Sin cobro",
                promosCxVet: false,
                type: "Gratis",
                country: "México",
                city: "México",
                statusPago: "BIEN",
                terminationDate: "",
                subscriptionId: "",
            })
                .then((docRef) => {
                    console.log("user guardado", docRef);
                    this.props.history.goBack()
                })
                .catch(function (error) {
                    console.error("Error : ", error);
                });
        }

    }

    render() {
        let modalClose = () => this.setState({ modalShow: false });
        let date = new Date(this.state.user.date)
        const createDate = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        return (
            <React.Fragment>
                <div className="content-wrapper p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="mb-0">Usuarios</h1>
                            <h3 className="mb-5">Nuevo usuario</h3>
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-10">
                                    <div className="form-container ">
                                        <div className="form-wrapper">
                                            <h4>Cuenta</h4>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" autocomplete="off" name="name" onChange={this.handleInputChange} value={this.state.user.name} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Correo electrónico</label>
                                                <input type="text" autocomplete="off" name="email" onChange={this.handleInputChange} value={this.state.user.email} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Contraseña</label>
                                                <input type="password" autocomplete="off" name="password" onChange={this.handleInputChange} value={this.state.user.password} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber">Número de teléfono</label>
                                                <FormPhoneInput id="contact_phone_number" name="phone_number" value={this.state.user.phone_number} onChange={this.handleInputChange} />
                                            </div>
                                            <h4>Universidad </h4>
                                            <div className="form-group">
                                                <label from="country">Opcional, si perteneces a una universidad selecciona aquí</label>
                                                <select name="country" className="form-control" onChange={this.handleInputChange} id="university" name="university" value={this.state.user.university
                                                } className="form-control"  >
                                                    <option value="">Seleccionar uno</option>
                                                    {Array.isArray(this.state.universities) && this.state.universities.map(university => {
                                                        return (<option value={university.id}> {university.title} </option>);
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-wrapper">
                                            <h4>Membresía</h4>
                                            <div className="form-group">
                                                <label>Membresía</label>
                                                <input type="text" disabled value="Gratis" name="type" value={this.state.user.type} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Registrado desde</label>
                                                <input type="text" disabled value="03-06-2019" name="date" value={createDate} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-wrapper">
                                            <h4>Ubicación</h4>
                                            <div className="form-group">
                                                <label>País</label>
                                                <select name="country" className="form-control" disabled value={this.state.user.country} className="form-control">
                                                    <option value="">Sin asignar</option>
                                                    {Array.isArray(countries) && countries.map(country => {
                                                        return (<option value={country}> {country} </option>);
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Ciudad</label>
                                                <input type="text" name="city" className="form-control" disabled value={this.state.user.city} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-row"  >
                                {this.state.user.id && <button onClick={() => this.setState({ modalShow: true })} className="btn btn-primary">Eliminar</button>}
                                <button type="button" className="btn btn-primary" onClick={() => this.props.history.goBack()}>Cancelar</button>
                                <button type="button" className="btn btn-primary" onClick={this.saveUser}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <DeleteAlertCompoment show={this.state.modalShow} onHide={modalClose} deleteElement={this.deleteUser} />
            </React.Fragment>
        );
    }
}
UserFormComponent.contextType = CurrentUser;
export default UserFormComponent;