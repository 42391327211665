import React, { Component } from 'react';
import firebase from 'firebase';
import { NavLink } from 'react-router-dom';

import ProcedureItemComponent from './ProcedureItemComponent';
import TagItemComponent from './TagItemComponent';
import PaginationComponent from './PaginationComponent';

import CurrentUser from "../../public/core/PublicUserSessionContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { userCanAccessAula } from '../../../utils/aula.utils';

class ProceduresComponent extends Component {
    constructor(props) {
        super(props);
        const { match: { params } } = this.props;
        const filters = {};
        filters[params.type] = params.filter;
        this.state = {
            docs: [],
            proceduresCollection: firebase.firestore().collection('procedures'),
            filters: { kynd: filters.kynd, tag: filters.tag, type: filters.type, aula: filters.aula }, isFiltering: true, pagination: { numberElements: 9 },
            aulas: [],
            aulaFilter: "Todos",
        };
        //console.log("estado:",this.state);
    }

    componentWillMount = () => {
        const currentUser = this.context.currentUser;
        firebase.firestore().collection('tags').get().then((snapshot) => {
            const tags = [];
            snapshot.docs.map((doc) => tags.push({ id: doc.id, ...doc.data() }));
            this.setState({ tags });
            this.getFilteredProcedures();
        });

        firebase.firestore().collection('aulas').get().then((aulasColl) => {
            let aulas = []
            aulasColl.docs.forEach(aula => {
                if (userCanAccessAula(currentUser.id, aula.data()))
                    aulas.push({ id: aula.id, ...aula.data() })
            })
            this.setState({ aulas })
        });
    }

    getFilteredProcedures = (newFilters) => {
        this.context.iniciaCarga();
        const filters = newFilters ? newFilters : this.state.filters;
        let query = this.state.proceduresCollection;
        if (filters.kynd) {
            query = query.where(`kynd`, '==', filters.kynd);
        }
        if (filters.tag) {
            query = query.where(`tags.${filters.tag}`, '==', true);
        }
        if (filters.type) {
            query = query.where(`type`, '==', filters.type);
        }
        query.get().then((snapshot) => {
            if (filters.aula) {
                let filteredDocs = []
                let currentAula = this.state.aulas.find(aula => filters.aula === aula.id)
                snapshot.docs.forEach(doc => {
                    if (currentAula.videos.includes(doc.id)) {
                        filteredDocs.push(doc)
                    }
                })
                this.paginateProcedures(filteredDocs, true);
            }
            else
                this.paginateProcedures(snapshot.docs, true);

        }).catch(error => console.log(error))
            .finally(st => {
                this.context.terminaCarga();
            });
    }

    paginateProcedures = (docs, isNewData, newFilters) => {
        const procedures = [[]];
        const pagination = this.state.pagination;
        docs.map((doc, index) => {
            const page = Math.ceil((index + 1) / this.state.pagination.numberElements);
            if (procedures.length <= page) {
                procedures.push([]);
            }
            procedures[page].push({ id: doc.id, ...doc.data() });
        });
        pagination.numberPages = procedures.length - 1;
        pagination.actualPage = 1;
        pagination.active = (pagination.numberPages > 1);
        const filters = newFilters ? newFilters : this.state.filters;
        const backDocs = isNewData ? docs : this.state.docs;
        this.setState({ procedures, docs: backDocs, filters, isFiltering: false, pagination });
    }

    handlePagination = (page) => {
        //console.log(page);
        const pagination = this.state.pagination;
        pagination.actualPage = page;
        this.setState({ pagination }, () => {
            window.scrollTo(0, 0);
        });
    }

    filterByKynd = (kynd) => {
        const filters = this.state.filters;
        filters.kynd = kynd;
        this.getFilteredProcedures(filters);
    }

    filterByTag = (tag) => {
        const filters = this.state.filters;
        filters.tag = tag;
        this.getFilteredProcedures(filters);
    }

    filterByType = (type) => {
        const filters = this.state.filters;
        filters.type = type;
        this.getFilteredProcedures(filters);
    }

    filterByAula = (aula) => {
        const filters = this.state.filters;
        filters.aula = aula;
        this.getFilteredProcedures(filters);
    }

    searchByName = (event) => {
        const value = event.target.value;
        const docs = this.state.docs;
        const newDocs = [];
        let tagsToLook = [];
        this.state.tags.forEach(tag => {
            if (tag.name.toLowerCase().includes(value.toLowerCase())) {
                tagsToLook.push(tag.id);
            }
        })
        //console.log("SEARCH BY NAME");
        //console.log(tagsToLook);

        docs.map((doc) => {
            let data = doc.data();
            let splittedMeta = data.meta.split(",").map(meta => meta.trim());
            if (data.name.toLowerCase().includes(value.toLowerCase())) {
                newDocs.push(doc)
            }
            else if (data.detail.toLowerCase().includes(value.toLowerCase())) {
                newDocs.push(doc)
            }
            else if (tagsToLook.some(tagId => {
                if (data.tags[tagId]) {
                    return true;
                }
            }
            )) {
                newDocs.push(doc);
            }

            else if (
                splittedMeta.some(
                    meta => {
                        return meta.toLowerCase().includes(value.toLowerCase())
                    }
                )
            ) {
                newDocs.push(doc);
            }
        });

        //console.log(docs);
        //console.log(newDocs);
        this.paginateProcedures(newDocs);
    }


    render() {
        if (this.state.isFiltering) {
            return false;
        }
        const procedures = this.state.procedures;
        const actualPage = this.state.pagination.actualPage;
        const currentUser = this.context.currentUser;
        const tags = this.state.tags;
        return (
            <section className="video-grid">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="video-title-bar d-md-flex justify-content-between">
                                <h1 className="main-title">
                                    Catálogo de cirugías
                                </h1>
                                <div>
                                    <div className="mb-3 p-0 row no-gutters align-items-center">
                                        <div className="col">
                                            <input onChange={this.searchByName} className="form-control form-control-borderless" type="search" placeholder="Buscar en catálogo" />
                                        </div>
                                        <div className="col-auto pl-3 pr-3">
                                            <a>
                                                <FontAwesomeIcon icon={faSearch} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-5 col-12" >
                                    <div className=" filter-sidebar">
                                        <span className="h4">Filtros</span>
                                        <p  >Aulas virtuales</p>
                                        <ul className="list-unstyled pl-3">
                                            {this.state.aulas.map(aula => {
                                                return <li className={this.state.aulaFilter === aula.id ? "Gratis active" : "Gratis"}><button onClick={e => {
                                                    this.setState({ aulaFilter: aula.id }, () => this.filterByAula(aula.id))

                                                }}>{aula.title}</button></li>
                                            })}
                                            <li className={this.state.aulaFilter === "Todos" ? "Gratis active" : "Gratis"}><button onClick={e => {
                                                this.setState({ aulaFilter: "Todos" }, () => this.filterByAula(false))

                                            }}>Todos</button></li>
                                        </ul>
                                        <p  >Tipo</p>
                                        <ul className="list-unstyled pl-3">
                                            <li className={this.state.filters.type === "Gratis" && "active"}><button onClick={e => this.filterByType('Gratis')}>Gratis</button></li>
                                            <li className={this.state.filters.type === "Premium" && "active"}><button onClick={e => this.filterByType('Premium')}>Premium</button></li>
                                            <li className={!this.state.filters.type && "active"}><button onClick={e => this.filterByType(false)}>Todos</button></li>
                                        </ul>
                                        <p  >Especie</p>
                                        <ul className="list-unstyled pl-3">
                                            <li className={this.state.filters.kynd === "Perro" && "active"} ><button onClick={e => this.filterByKynd('Perro')}>Perro</button></li>
                                            <li className={this.state.filters.kynd === "Gato" && "active"} ><button onClick={e => this.filterByKynd('Gato')}>Gato</button></li>
                                            <li className={this.state.filters.kynd === "Otras especies" && "active"} ><button onClick={e => this.filterByKynd('Otras especies')}>Otras especies</button></li>
                                            <li className={!this.state.filters.kynd && "active"} ><button onClick={e => this.filterByKynd(false)}>Todas</button></li>
                                        </ul>
                                        <p  >Etiquetas</p>
                                        <ul className="list-unstyled pl-3">
                                            {tags && tags.map(tag => {
                                                return (
                                                    <li className={this.state.filters.tag === tag.id && "active"}><button onClick={e => this.filterByTag(tag.id)}>{tag.name}</button></li>
                                                )
                                            })}

                                            <li className={!this.state.filters.tag && "active"}><button onClick={e => this.filterByTag(false)}>Todas</button></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8 col-sm-7 col-12" >
                                    <div className="row">
                                        {procedures[actualPage] && procedures[actualPage].map(procedure => {
                                            let doOnce = true
                                            //if (this.state.aulaFilter === "Todos")
                                            return <ProcedureItemComponent key={procedure.id} procedure={procedure} userType={currentUser.type} />
                                            //     else {

                                            //        for (let x = 0; x < this.state.aulas.length; x++) {
                                            //             if (this.state.aulaFilter === this.state.aulas[x].id && this.state.aulas[x].videos.includes(procedure.id) && doOnce) {
                                            //                doOnce = false
                                            //                return <ProcedureItemComponent key={procedure.id} procedure={procedure} userType={currentUser.type} />
                                            //              }
                                            //         }
                                            //       }

                                        })}
                                    </div>

                                    <div className="row justify-content-center">
                                        {this.state.pagination.active &&
                                            <PaginationComponent pagination={this.state.pagination} handlePagination={this.handlePagination} />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
ProceduresComponent.contextType = CurrentUser;
export default ProceduresComponent;