import React,{Component} from 'react';
import Form from 'react-bootstrap/Form';
import firebase from 'firebase';
import {countries} from '../../../resources/js/data';

import CurrentUser from "../core/PublicUserSessionContext";
import ModalTermsAndConditionsComponent from './ModalTermsAndConditionsComponent';

import Alert from 'react-bootstrap/Alert'
import FormPhoneInput from '../../commons/FormPhoneInput';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

class SignUpFormComponent extends Component{
    constructor(props){
        super(props);
        this.state = {newUser:this.props.newUser, universities: [{}], modalShow: false, errors:{}};
    }

    componentDidMount(){
        firebase.firestore().collection("organizations").get().then(organizations => {
            let universities = []
            organizations.docs.forEach(organization => {
                if(!organization.data().private)
                universities.push({id: organization.id, title: organization.data().title})
            });
            this.setState({universities})
        }).catch(error => {
            console.log(error)
        })
    }
    
    handleInputChange = (event) => {
        let value = event.target.value;
        const name = event.target.name;
        const newUser = this.state.newUser;
        const type = event.target.type;
        const errors = Object.assign({},this.state.errors);
        delete errors[name];
        if(type == 'checkbox'){
            value = event.target.checked;
        }
        newUser[name] = value;
        this.setState({newUser,errors});
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        if(this.validate()){
            this.continue();
        }
    }

    validate(){
        const values = this.state.newUser;
        const errors = {};
        const requiredMessage = "Debes llenar este campo";

        if(!values.newUserName){ errors.newUserName = requiredMessage; }

        if(!values.country){ errors.country = requiredMessage; }

        if(!values.city){ errors.city = requiredMessage; }

        if(!values.email){
            errors.email = requiredMessage;
        }else{
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re.test(String(values.email).toLowerCase())){
                errors.email = "Introduce un correo válido.";
            }
        }
        if(!values.confirmEmail){
            errors.confirmEmail = requiredMessage;
        }else{
            if(values.confirmEmail != values.email){
                errors.confirmEmail = "Las direcciones de correo deben ser idénticas.";
            }
        }

        if(!values.phone_number){
            errors.phone_number = requiredMessage;
        }else{
            const phone_number = values?.phone_number ?? "";

            if(isPossiblePhoneNumber(phone_number) === false){
                errors.phone_number = "Introduce un número de teléfono válido.";
            }
        }
        if (!values.confirm_phone_number) {
            errors.confirm_phone_number = requiredMessage;
        }
        else {
            if (values.confirm_phone_number !== values.phone_number) {
                errors.confirm_phone_number = "Los números de teléfono deben ser idénticos.";
            }
        }

        if(!values.password){
            errors.password = requiredMessage;
        }else{
            if(values.password.length < 6){
                errors.password = "La contraseña debe tener al menos 6 caracteres.";
            }
        }

        if(!values.confirmPassword){
            errors.confirmPassword = requiredMessage;
        }else{
            if(values.confirmPassword != values.password){
                errors.confirmPassword = "Las contraseñas deben ser idénticas.";
            }
        }

        if(!values.terms){
            errors.terms = "Debes leer y aceptar los términos y condiciones.";
        }

        this.setState({errors});
        if(!Object.keys(errors).length){
            return true;
        }
    }

    back = (pageToBack) =>{
        const newUser = this.state.newUser;
        newUser.processStep = pageToBack;
        this.props.updateNewUser(newUser);
    }

    continue = () => {
        firebase.firestore().collection('users').where(`email`, '==', this.state.newUser.email).get().then((snapshot) => {
            if(snapshot.empty){
                const newUser = this.state.newUser;
                this.props.modalRegistrarShow()
                //newUser.processStep = 3;
                //this.props.updateNewUser(newUser);
            }else{
                this.setState({errors:{email:"Este correo ya esta registrado, elige otro por favor."}});
            }
        }).catch(err=>{
            this.context.mostrarError("Ocurrió un error, por favor recarga la página","ERROR SIGNUP");
        });
    }

    registerUserGratis = () => {

    }

    render(){
        let modalClose = () => this.setState({ modalShow: false });
        const newUser = this.state.newUser;
        const errors = this.state.errors;
        //console.log("CONTEX");
        //console.log(this.context);
        return(
            <React.Fragment>
            <section className="register-page">
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <h1 className="">
                            Usuario nuevo 
                            </h1>
                            <h2 className="subtitle mb-5">
                            Información personal
                            </h2>
                            <div className="row justify-content-center ">
                                <div className="col-md-10 ">
                                    <ul className="step d-sm-flex d-none flex-nowrap mb-5">
                                        <li className="step-item">
                                            <a><button type="button" onClick={(e) => this.back(1)} className="link-no-btn" >Tipo de membresía</button></a>
                                        </li>
                                        <li className="step-item active">
                                        <a><button type="button" disabled className="link-no-btn" >Información personal</button></a>
                                        </li>
                                        <li className="step-item ">
                                            <a  className="link-no-btn" >Método de pago</a>
                                        </li>
                                    </ul>

                                    <div className="row justify-content-center ">
                                        <div className="col-md-8">
                                            <Form onSubmit={this.handleFormSubmit}  >   
                                                <div className="form-register">
                                                    <h4>Información básica</h4>
                                                    <div className="form-group">
                                                        <label  htmlFor="newName" >Nombre</label>
                                                        <Form.Control type="text" isInvalid={errors.newUserName} onChange={this.handleInputChange} name="newUserName" value={newUser.newUserName} className="form-control"  />
                                                        <Form.Control.Feedback type="invalid">{errors.newUserName}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="contact_email">Correo Electrónico</label>
                                                        <Form.Control isInvalid={errors.email}  onChange={this.handleInputChange} type="email"  id="contact_email" name="email" value={newUser.email} className="form-control"  />
                                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="confirmEmail">Confirmar Correo Electrónico</label>
                                                        <Form.Control isInvalid={errors.confirmEmail}  onChange={this.handleInputChange} type="email"  id="confirmEmail" name="confirmEmail" value={newUser.confirmEmail} className="form-control"  />
                                                        <Form.Control.Feedback type="invalid">{errors.confirmEmail}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phoneNumber">Número de teléfono</label>
                                                        <FormPhoneInput id="contact_phone_number" name="phone_number" value={newUser.phone_number} onChange={this.handleInputChange} error={errors.phone_number} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="confirmPhoneNumber">Confirmar número de teléfono</label>
                                                        <FormPhoneInput id="confirm_contact_phone_number" name="confirm_phone_number" value={newUser.confirm_phone_number} onChange={this.handleInputChange} error={errors.confirm_phone_number} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password">Contraseña</label>
                                                        <Form.Control isInvalid={errors.password}  onChange={this.handleInputChange} type="password" id="password" name="password" value={newUser.password} className="form-control"  />
                                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                                    </div> 
                                                    <div className="form-group">
                                                        <label htmlFor="confirmPassword">Confirmar Contraseña</label>
                                                        <Form.Control isInvalid={errors.confirmPassword}  onChange={this.handleInputChange} type="password" id="confirmPassword" name="confirmPassword" value={newUser.confirmPassword} className="form-control"  />
                                                        <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                                    </div> 
                                                    <h4>Universidad </h4>
                                                    <div className="form-group">
                                                        <label from="country">Opcional, si perteneces a una universidad selecciona aquí</label>
                                                        <Form.Control as="select"  onChange={this.handleInputChange} id="university" name="university" value={newUser.university} className="form-control"  >
                                                            <option value="">Seleccionar uno</option>
                                                            {Array.isArray(this.state.universities) && this.state.universities.map(university => {
                                                                return (<option value={university.id}> {university.title} </option>);
                                                            })}
                                                        </Form.Control>
                                                    </div>
                                                    <h4>Ubicación</h4>
                                                    <div className="form-group">
                                                        <label from="country">País</label>
                                                        <Form.Control as="select" isInvalid={errors.country}  onChange={this.handleInputChange} id="country" name="country" value={newUser.country} className="form-control"  >
                                                            <option value="">Seleccionar uno</option>
                                                            {Array.isArray(countries) && countries.map(country => {
                                                                return (<option value={country}> {country} </option>);
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                                                    </div>
                                                    <div className="form-group">
                                                        <label from="city">Ciudad</label>
                                                        <Form.Control isInvalid={errors.city}  onChange={this.handleInputChange} type="text" id="city" name="city" value={newUser.city} className="form-control"  />
                                                        <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                                                    </div>

                                                    <h4>Preferencias de correo</h4>
                                                    <div className="mb-3">
                                                        <div className="form-check">
                                                            <input  onChange={this.handleInputChange} id="promosCxVet" name="promosCxVet" checked={newUser.promosCxVet} type="checkbox" className="form-check-input" />
                                                            <label htmlFor="promosCxVet" className="form-check-label" >Quiero recibir correos promocionales de CxVet</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input  onChange={this.handleInputChange} id="newsCxVet" name="newsCxVet" checked={newUser.newsCxVet} type="checkbox" className="form-check-input" />
                                                            <label className="form-check-label" >Quiero recibir correos con novedades de videos</label>
                                                        </div>
                                                        
                                                    </div>
                                                   <hr/>
                                                      <div className="mb-3">
                                                        <div className="form-check mb-3">
                                                              <Form.Check  inline onChange={this.handleInputChange} name="terms" value={newUser.terms}  className="form-check-input"></Form.Check>
                                                              
                                                              <label className="form-check-label" >He leído y acepto los <button type="button" onClick={() => this.setState({ modalShow: true })} className="link-no-btn">Términos y Condiciones</button></label>
                                                          
                                                          </div>
                                                          {errors.terms && <Alert variant="danger"> {errors.terms}</Alert>}
                                                      </div>


                                                    
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-secondary btn-block">
                                                        REGISTRAR
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalTermsAndConditionsComponent show={this.state.modalShow} onHide={modalClose} />
            </React.Fragment>
        );
    }
}
SignUpFormComponent.contextType = CurrentUser;
export default SignUpFormComponent;


